<template>
  <v-dialog v-model="isDialog" :key="item._id" persistent max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="grey lighten-1" @click="billingDateFn()">
        <font-awesome-icon :icon="['fas', 'calendar-day']" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="headline text-left">Next Billing Date:</h2>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="closeDialog">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-col cols="12" sm="6" class="py-0">
            <v-menu
              ref="nextBillingDateMenu"
              v-model="nextBillingDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.nextBillingDate"
                  label="Next Billing Date"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.nextBillingDate"
                no-title
                @input="nextBillingDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
        >
          <v-col
            cols="12"
            sm="3"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8"
              color="secondary"
              @click="closeDialog"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8"
              color="secondary"
              tile
              depressed
              bottom
              right
              @click="
                billingDateSubmitFn();
                isDialog = false;
              "
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BillingDateConfirmation",
  props: {
    item: Object,
    // billingDateSubmitFn: Function,
  },
  data() {
    return {
      approvalDialog: false,
      // nextBillingDate: null,
      nextBillingDateMenu: false,
      isDialog: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    closeDialog() {
      this.isDialog = false;
    },
    // billingDateFn(item) {
    //   this.isDialog = true;

    //   const endDate = new Date(item.created_at.split("T")[0]);
    //   console.log(endDate.toISOString());
    //   this.nextBillingDate = `${endDate.getFullYear() + 1}-${
    //     endDate.getMonth() + 1
    //   }-${endDate.getDate()}`;
    // },
    billingDateFn() {
      this.isDialog = true;
      this.$emit("setDateFn", this.item);
    },
    billingDateSubmitFn() {
      this.isDialog = true;
      this.$emit("setBillingDateSubmitFn", this.item);
    },
  },
};
</script>
